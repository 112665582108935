<template>
  <v-menu v-model="showMenu" offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-if="textStatus" class="ma-2" color="blue50" outlined pill v-on="on">
        <span class="primary--text">{{ textStatus }}</span>
        <v-icon v-if="showMenu" right color="black">
          mdi-menu-up
        </v-icon>
        <v-icon v-else right color="black">
          mdi-menu-down
        </v-icon>
      </v-chip>
      <v-icon v-else v-bind="attrs" :color="color" v-on="on">
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-list>
      <v-list-item v-if="isPending" @click="changeStatusToInProgress">
        <v-list-item-title>
          <v-icon right>mdi-tools</v-icon>
          {{ $t('button.menu_table_in_progress') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isInProgress" @click="changeStatusToCompleted">
        <v-list-item-title>
          <v-icon right>mdi-check-all</v-icon>
          {{ $t('button.menu_table_completed') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!isRecurrence" @click="cancelTask">
        <v-list-item-title>
          <v-icon right>mdi-cancel</v-icon>
          {{ $t('button.menu_table_canceled') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canUpdate && !hiddenButtonEdit" @click="editTask">
        <v-list-item-title>
          <v-icon right>mdi-pencil</v-icon>
          {{ $t('button.menu_table_edit') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canUpdate && (isPending || isRecurrence) && !hiddenButtonDelete" @click="deleteTask">
        <v-list-item-title>
          <v-icon right>mdi-trash-can</v-icon>
          {{ $t('button.menu_table_delete') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import * as taskStatuses from '@/constants/taskStatuses';

export default {
  name: 'TaskMenu',

  props: {
    status: { type: String, default: undefined },
    canUpdate: { type: Boolean, default: false },
    color: { type: String, default: '' },
    textStatus: { type: String, default: '' },
    hiddenButtonEdit: { type: Boolean, default: false },
    hiddenButtonDelete: { type: Boolean, default: false },
  },

  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    isPending() {
      return this.status === taskStatuses.PENDING;
    },
    isInProgress() {
      return this.status === taskStatuses.IN_PROGRESS;
    },
    isCompletedOrCanceled() {
      return this.status === taskStatuses.CANCELED || this.status === taskStatuses.COMPLETED;
    },
    isRecurrence() {
      return !this.status;
    },
  },

  methods: {
    changeStatus(newStatus) {
      this.$emit('change-status', newStatus);
    },
    changeStatusToInProgress() {
      this.changeStatus(taskStatuses.IN_PROGRESS);
    },
    changeStatusToCompleted() {
      this.changeStatus(taskStatuses.COMPLETED);
    },
    deleteTask() {
      this.$emit('delete');
    },
    editTask() {
      this.$emit('edit');
    },
    cancelTask() {
      this.changeStatus(taskStatuses.CANCELED);
    },
  },
};
</script>
