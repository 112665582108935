import * as dictionaryTypes from '@/schemas/dictionaryTypes';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

// eslint-disable-next-line import/prefer-default-export
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.TITLED_SECTION,
        label: 'tasks.information',
        size: 8,
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.DATE,
                label: 'task.date',
                prop: 'createdAt',
                rules: [rules.REQUIRED],
                size: 4,
              },
              {
                type: schemaItemTypes.STRING,
                label: 'task.title',
                prop: 'name',
                rules: [rules.REQUIRED],
                size: 8,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.ENUM,
                label: 'invoice.service_type',
                prop: 'serviceType',
                dictionary: dictionaryTypes.SERVICE_TYPES,
                rules: [rules.REQUIRED],
                size: 4,
              },
              {
                type: schemaItemTypes.COAST,
                label: 'task.cost',
                prop: 'price',
                rules: [rules.REQUIRED],
                size: 4,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.EXPANSION_PANEL,
                label: 'label.performers',
                prop: 'performers',
                rules: [rules.MANY_REQUIRED],
                showCount: true,
                size: 12,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'task.description',
                prop: 'description',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.CARD_SECTION,
        label: 'tasks.client',
        size: 4,
        prop: 'infoCard',
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'task.client',
                prop: 'client',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.project',
                prop: 'project',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.building',
                prop: 'building',
                rules: [rules.REQUIRED],
                size: 6,
              },
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.unit',
                prop: 'unit',
                rules: [rules.REQUIRED],
                size: 6,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.attachments',
    children: [
      {
        type: schemaItemTypes.FILE_LIST,
        prop: 'media',
        rules: [rules.REQUIRED, rules.ALL_LOADED],
        label: 'tasks.attachments',
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.history',
    children: [
      {
        type: schemaItemTypes.LIST,
        prop: 'statusesHistory',
        uniqueKey: 'createdAt',
        children: [
          {
            type: schemaItemTypes.ROW,
            showLine: true,
            children: [
              {
                type: schemaItemTypes.DATE,
                prop: 'createdAt',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.STRING,
                prop: 'author',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.TASK_STATUS_CHANGE,
                prop: 'changeStatus',
                rules: [rules.REQUIRED],
                size: 4,
              },
              {
                type: schemaItemTypes.FILE_LIST,
                prop: 'files',
                rules: [rules.REQUIRED],
                size: 3,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const schemaRecurrence = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.TITLED_SECTION,
        label: 'tasks.information',
        size: 8,
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.DATE,
                label: 'task.date',
                prop: 'createdAt',
                rules: [rules.REQUIRED],
                size: 3,
              },
              {
                type: schemaItemTypes.DATE,
                label: 'task.date_first_task',
                prop: 'issueDateFirst',
                rules: [rules.REQUIRED],
                size: 3,
              },
              {
                type: schemaItemTypes.STRING,
                label: 'task.recurrence_period',
                prop: 'recurrencePeriod',
                rules: [rules.REQUIRED],
                size: 3,
              },
              {
                type: schemaItemTypes.DATE,
                label: 'task.date_last_task',
                prop: 'issueDateLast',
                rules: [rules.REQUIRED],
                size: 3,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.ENUM,
                label: 'invoice.service_type',
                prop: 'serviceType',
                dictionary: dictionaryTypes.SERVICE_TYPES,
                rules: [rules.REQUIRED],
                size: 3,
              },
              {
                type: schemaItemTypes.STRING,
                label: 'task.executor',
                prop: 'performer',
                rules: [rules.REQUIRED],
                size: 3,
              },
              {
                type: schemaItemTypes.COAST,
                label: 'task.cost',
                prop: 'price',
                rules: [rules.REQUIRED],
                size: 3,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'task.description',
                prop: 'description',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.CARD_SECTION,
        label: 'tasks.client',
        size: 4,
        prop: 'infoCard',
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'task.client',
                prop: 'client',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.project',
                prop: 'project',
                rules: [rules.REQUIRED],
                size: 12,
              },
            ],
          },
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.building',
                prop: 'building',
                rules: [rules.REQUIRED],
                size: 6,
              },
              {
                type: schemaItemTypes.STRING,
                label: 'tasks.unit',
                prop: 'unit',
                rules: [rules.REQUIRED],
                size: 6,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.attachments',
    children: [
      {
        type: schemaItemTypes.FILE_LIST,
        prop: 'media',
        rules: [rules.REQUIRED, rules.ALL_LOADED],
        label: 'tasks.attachments',
      },
    ],
  },
];
