<template>
  <entity-header class="tasks-entity-header" :title="title" :back-route="backRoute" :can-update="canUpdate">
    <template v-slot:status>
      <span v-if="!status"></span>
      <span
        v-else-if="canUpdate && (media.isMobile || isCompletedOrCanceled)"
        class="ml-0 ml-md-4 mt-0 mt-md-2 text-caption text-md-h5 tasks-entity-header__title"
        :class="tasksEntityHeaderModification"
      >
        {{ $t(`tasks.${status}`) }}
      </span>
      <task-menu
        v-else-if="canUpdate || forMe"
        :status="status"
        :can-update="canUpdate"
        :text-status="$t(`tasks.${status}`)"
        hidden-button-edit
        hidden-button-delete
        @change-status="changeStatus($event)"
      />
    </template>
    <template v-if="canUpdate" v-slot:actions>
      <template v-if="media.isMobile">
        <icon-button v-if="!isCompletedOrCanceled" class="mr-2" color="primary" :to="editRoute">
          <v-icon>mdi-pencil</v-icon>
        </icon-button>
        <icon-button
          v-if="isPendingStatus || isRecurrence"
          class="mr-2 primary--text"
          color="secondary"
          @click="deleteTask"
        >
          <v-icon>mdi-trash-can</v-icon>
        </icon-button>
      </template>
      <template v-else>
        <v-btn v-if="!isCompletedOrCanceled" color="primary" class="mr-2" large :to="editRoute">
          {{ $t('button.edit') }}
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="isPendingStatus || isRecurrence" color="secondary" class="primary--text" large @click="deleteTask">
          {{ $t('button.delete') }}
          <v-icon right>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </template>
  </entity-header>
</template>

<script>
import EntityHeader from '@/components/EntityHeader.vue';
import IconButton from '@/components/IconButton.vue';
import TaskMenu from '@/components/Tasks/TaskMenu.vue';
import * as taskStatuses from '@/constants/taskStatuses';

export default {
  name: 'TasksEntityHeader',

  components: { EntityHeader, IconButton, TaskMenu },

  inject: ['media'],

  props: {
    title: { type: String, default: '' },
    backRoute: { type: Object, default: () => ({}) },
    editRoute: { type: Object, default: () => ({}) },
    status: { type: String, default: '' },
    canUpdate: { type: Boolean, default: false },
    forMe: { type: Boolean, default: false },
  },

  computed: {
    isCompletedOrCanceled() {
      return this.status === taskStatuses.CANCELED || this.status === taskStatuses.COMPLETED;
    },
    isPendingStatus() {
      return this.status === taskStatuses.PENDING;
    },
    isRecurrence() {
      // return this.status === taskStatuses.RECURRENCE;
      return !this.status;
    },
    tasksEntityHeaderModification() {
      return {
        'tasks-entity-header__title--canceled': this.status === taskStatuses.CANCELED,
        'tasks-entity-header__title--pending': this.status === taskStatuses.PENDING,
        'tasks-entity-header__title--in-progress': this.status === taskStatuses.IN_PROGRESS,
      };
    },
  },

  methods: {
    deleteTask() {
      this.$emit('delete-task');
    },
    changeStatus(newStatus) {
      this.$emit('change-status', newStatus);
    },
  },
};
</script>
<style lang="scss">
.tasks-entity-header {
  &__title {
    &--pending {
      color: $--blue-color-20;
    }
    &--in-progress {
      color: $--blue-color-20;
    }
    &--canceled {
      color: $--grey-color-0;
    }
  }
}
</style>
