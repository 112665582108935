<template>
  <div>
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('tasks.back_to_tasks') }}</v-btn>
    </info-modal>
    <base-dialog ref="deleteDialog" :title="$t('task.delete_message')" :text="$t('task.delete_text')" />
    <tasks-entity-header
      class="mb-10"
      :title="taskId"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :status="task.status"
      :for-me="isForMe"
      @change-status="changeStatus({ newStatus: $event, id })"
      @delete-task="deleteTask(id)"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="task" />
    <task-mobile-status-buttons
      v-if="media.isMobile && (canUpdate || isForMe)"
      :status="task.status"
      :task-id="id"
      sticky
      @change-status="changeStatus"
    />
    <task-modal-change-status
      v-model="dataChangeStatus.isShowChangeStatus"
      :new-status="dataChangeStatus.newStatus"
      :task-id="dataChangeStatus.id"
      @success-change="showStatusChangeSuccessNotice"
      @fail-change="showStatusChangeFailNotice"
    />
  </div>
</template>

<script>
// Models
import { schema } from '@/schemas/task.view.schema';
import TaskModalChangeStatus from '@/components/Tasks/ModalChangeStatus.vue';
import { createModelData } from '@/schemas/createModelData';

// Services
import issuesService from '@/services/issues';
import analyticsService from '@/services/analytics';

// Constants
import { TASKS, TASKS_EDIT } from '@/constants/routes';
import { TASK } from '@/constants/analyticsActions';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Components
import TasksEntityHeader from '@/components/Tasks/EntityHeader.vue';
import TaskMobileStatusButtons from '@/components/Tasks/TaskMobileStatusButtons.vue';
import InfoModal from '@/components/InfoModal.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';

export default {
  name: 'TaskDetailed',

  components: {
    TasksEntityHeader,
    ViewBuilder,
    TaskMobileStatusButtons,
    TaskModalChangeStatus,
    InfoModal,
    BaseDialog,
  },
  inject: ['media'],

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      task: createModelData(schema),
      dataChangeStatus: {
        isShowChangeStatus: false,
        newStatus: '',
        id: 0,
      },
      dataDeleteTask: {
        isShowModalDelete: false,
        taskId: 0,
      },
      modal: {
        show: false,
        message: '',
        info: true,
      },
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, subjects.ISSUES);
    },

    isForMe() {
      return this.task.forMe;
    },

    backRoute() {
      return { name: TASKS, query: { page: this.prevPage || 1 } };
    },

    taskId() {
      if (this.isLoading) return '';
      return `№${this.id}`;
    },

    editRoute() {
      return { name: TASKS_EDIT, params: { id: this.id } };
    },
  },

  async mounted() {
    analyticsService.track(TASK);
    this.isLoading = true;
    try {
      await this.initDetail();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async deleteTask(issueId) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.isLoading = true;
        try {
          await issuesService.deleteIssueById(issueId);
          this.showModal(this.$t('tasks.success_delete_task'));
          this.$router.push(this.backRoute);
        } catch (e) {
          this.showModal(this.$t('tasks.fail_delete_task'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    changeStatus({ newStatus, id }) {
      this.dataChangeStatus.isShowChangeStatus = true;
      this.dataChangeStatus.newStatus = newStatus;
      this.dataChangeStatus.id = id;
    },
    showStatusChangeSuccessNotice() {
      this.showModal(this.$t('tasks.success_change_status'));
      this.initDetail();
    },
    showStatusChangeFailNotice() {
      this.showModal(this.$t('tasks.fail_change_status'));
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    async initDetail() {
      const issue = await issuesService.getIssueById(this.id);
      this.task = {
        ...issue,
        building: this.getName(issue.building),
        client: this.getFullName(issue.client),
        performers: this.getPerformers(issue.performers),
        project: this.getName(issue.project),
        serviceType: issue.serviceType,
        unit: this.getName(issue.unit),
        infoCard: {
          info: {
            text: issue.isInformClientViaPush
              ? this.$t('tasks.inform_notification')
              : this.$t('tasks.un_inform_notification'),
            icon: issue.isInformClientViaPush ? 'mdi-bell-ring' : 'mdi-bell-off',
            colorIcon: issue.isInformClientViaPush ? 'blue' : '',
            show: true,
          },
          building: this.getName(issue.building),
          client: this.getFullName(issue.client),
          project: this.getName(issue.project),
          unit: this.getName(issue.unit),
        },
        statusesHistory: issue.statusesHistory.map(item => ({
          ...item,
          author: `${item.author.firstName} ${item.author.lastName}`,
          changeStatus: {
            comment: item.comment,
            oldStatus: item.oldStatus,
            newStatus: item.newStatus,
          },
        })),
      };
    },
    getName(value) {
      return value.name;
    },
    getFullName(value) {
      return value.id ? `${value.firstName} ${value.lastName}` : '';
    },
    getPerformers(value) {
      return value.map(item => item.firstName + item.lastName);
    },
  },

  schema,
};
</script>
